<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between">
        <div></div>
        <div>
          <div class="title" style="text-align: center">
            You'll get an e-mail in {{ remainingDays }} days with the results!
          </div>
        </div>
        <div>
          <ion-buttons>
            <ion-button class="close-button text-black" @click.prevent="close">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <div
        style="border: 2px solid rgb(203, 203, 203); border-radius: 10px"
        class="px-3 py-3 d-flex justify-content-center align-items-center text-center"
        :class="{ 'd-flex flex-column': hasThanked }"
      >
        <div>
          <span> {{ hasThanked ? `You've  thanked` : 'Thank' }}</span>
          <router-link :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }">
            @{{ get(raffle, 'user.username') }}
          </router-link>
          for {{ hasThanked ? 'hosting!' : 'hosting this Raffle' }}
        </div>
        <div v-if="!hasThanked">
          <ion-button class="thank-btn mx-2" @click="thankyouModalOpen">Say Thanks!</ion-button>
        </div>
        <div><span v-if="hasThanked">Thanks for you kindness!</span></div>
      </div>

      <div
        v-if="get(raffle, 'bonus_gateways.fanwork') || get(raffle, 'bonus_gateways.social_media_sharing')"
        style="border: 2px solid rgb(203, 203, 203); border-radius: 10px"
        class="px-3 py-3 mt-3"
      >
        <div class="title">Get bonus entries!</div>
        <div class="py-2">
          <div v-if="get(raffle, 'bonus_gateways.fanwork')" class="d-flex">
            <ion-badge class="raf-badge">+1</ion-badge>

            <div class="mx-2 flex-grow-1 mt-1">
              <div>
                Every FanArt to
                <router-link
                  :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }"
                  target="_blank"
                >
                  @{{ get(raffle, 'user.username') }}
                </router-link>
                = Extra Entry! Submit More, Win More!
                <i
                  class="ti-help-alt clickable-item-hov text-black"
                  style="font-size: 14px; margin-top: 10px"
                  @mouseover="openPopup"
                />
              </div>
            </div>
          </div>
          <div v-if="get(raffle, 'bonus_gateways.social_media_sharing')" class="d-flex mt-2">
            <ion-badge class="raf-badge" :class="{ 'bg-primary': get(status, 'has_social_bonus') }">
              <div v-if="get(status, 'has_social_bonus')">
                <i class="ti-check" />
              </div>

              <div v-else>+1</div></ion-badge
            >
            <div class="mx-2 flex-grow-1 mt-1">
              Share raffle on social media.
              <span v-if="!get(status, 'has_social_bonus')" class="" @click="socialLinkModalOpen"
                ><strong class="text-primary clickable-item-hov" @click="socialLinkModalOpen">Click here</strong> to
                submit proof.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Raffle } from '@/shared/types/static-types';

import { popovers } from '@/shared/native';
import SubmitFanartPopover from '@/shared/pages/raffles/popovers/submit-fanart-popover.vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  raffle: {
    type: Object as PropType<Raffle>,
    required: true,
  },
  status: {
    type: Object,
    default: false,
  },
  entry: {
    type: Object,
    required: false,
  },
});

const emits = defineEmits(['close', 'link', 'thanks']);
const raffle = toRef(props, 'raffle');
const isOpen = toRef(props, 'isOpen');
const entry = toRef(props, 'entry');

const close = () => {
  emits('close');
};
const socialLinkModalOpen = () => {
  emits('link');
};
const thankyouModalOpen = () => {
  emits('thanks');
};

const hasThanked = computed(() => {
  return !!get(entry.value, 'thanks_string');
});

const openPopup = async (ev: CustomEvent) => {
  await popovers.open(ev, SubmitFanartPopover);
};

const remainingDays = computed(() => {
  const durationInMilliseconds = get(raffle.value, 'duration', 0) * 24 * 60 * 60 * 1000;
  const startTime = new Date(get(raffle.value, 'start_date')).getTime();
  const endTime = startTime + durationInMilliseconds;
  return Math.ceil((endTime - Date.now()) / (1000 * 60 * 60 * 24));
});
</script>

<style lang="sass" scoped>
.thank-btn
  --border-radius: 20px
  text-transform: none
  width: 175px
.top-right
  position: absolute
  top: 0.5rem
  right: 0.5rem
.close-button
  color: gray
.title
  font-weight: bold
  font-size: 20px
  color: #4a4a4a
.raf-badge
  background: #00b4c5
  font-weight: bold
  display: flex
  height: 28px
  width: 28px
  align-items: center
  justify-content: center
  min-width: 28px
  max-width: 28px

.modal-big
  // --width: 38%
  --width: 540px
  --height: auto
  @media(max-width: 560px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 90vh
  overflow: auto

.bg-primary
  background: var(--ion-color-primary) !important
.icon
  font-size: 18px
</style>
